import { Col, Container,  ModalHeader, ModalBody, ModalFooter, Button, Row } from "reactstrap";
import classes from "./GalleryViewer.module.css";
import SearchItem from "../../ui/SearchItem";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
//import Modal from "../../ui/Modal";
import Modal from 'react-modal';


function GalleryViewer(props) {
  /*
    get the list of images from page
    set the filter option and set new variable based on that.
    pass the filtered image list fto the sub component.
    */
  useEffect(() => {
    const tt = props.images;
    setCategories([...new Set(props.images.map((image) => image.category))]);
    //setGalleryImages(tt);
    setFilteredImages(props.images);
    console.log("GalleryViewer =>  filteredImages", props.images);
  }, []);

  const [categories, setCategories] = useState([]);

  const [filteredImages, setFilteredImages] = useState([]);

  const [model, setModel] = useState(false);
  const [modelImageSrc, setModelImageSrc] = useState("");

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
     
  
    },
  };


  const showModalImages = (imgSrc) => {
    console.log('inisde showmodalimages')
    setModelImageSrc(imgSrc);
    setModel(true);
  };
    const hideModalImages = () => {
      setModelImageSrc('');
      setModel(false);
    };

  function filterHandler(cat, index) {
    console.warn(filteredImages);
  }
  return (
    <>
      <Modal
        isOpen={model}
        /*
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        */
        ariaHideApp={false}
        style={customStyles}
      >
        <Row>
          <Col xs="12" style={{ textAlign: "right" }}>
            <FontAwesomeIcon
              icon={faClose}
              className="fa-2x"
              style={{ cursor: "pointer" }}
              onClick={() => hideModalImages()}
            />
          </Col>
        </Row>
        <Row>
          <Col style={{ padding: "0px !important" }}>
            <div style={{ float: "left" }}>
              <img src={modelImageSrc} className={classes.modalImg} />
            </div>
          </Col>
        </Row>
      </Modal>

      <Container className={classes.wrapper}>
        <Row>
          <Col className={classes.schWrp}>
            {categories.map((cat, index) => (
              <SearchItem
                key={index}
                index={index}
                category={cat}
                active={index === props.activeIndex}
                handleClick={() => filterHandler(cat, index)}
              />
            ))}
          </Col>
        </Row>

        <Row>
          {filteredImages.map((item, index) => {
            return (
              <Col
                xs="12"
                sm="12"
                md="6"
                xl="4"
                style={{ padding: "0px !important" }}
                key={index}
              >
                <div
                  className={classes.gallery}
                  style={{
                    backgroundImage: `url(${item.image})`,
                  }}
                  onClick={() => showModalImages(item.image)}
                ></div>
              </Col>
            );
          })}
        </Row>
      </Container>
    </>
  );
}

export default GalleryViewer;
