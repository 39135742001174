import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faFacebookSquare,
  faInstagramSquare,
  faLinkedin,
  faLinkedinIn,
  faTwitterSquare,
  faBluetoothB,
  faWhatsappSquare,
  faFortAwesome,
  
} from "@fortawesome/free-brands-svg-icons";



import {} from "@fortawesome/free-brands-svg-icons";

import { faUserAstronaut } from "@fortawesome/free-solid-svg-icons";

import { Container, Row, Col } from "reactstrap";
import classes from "./Footer.module.css";
import { Link } from "react-router-dom";
import mainClasses from "../../index.css";

function Footer() {
  return (
    <Container className={classes.footer}>
      <Row>
        <Col xs="6" sm="3" md="4" xl="3">
          <Row>
            <Col xs="12">
              <p>
                <Link to="/">Home</Link>
              </p>
            </Col>
            <Col xs="12">
              <p>
                <Link to="/about-us">About us</Link>
              </p>
            </Col>
            <Col xs="12">
              <p>
                {" "}
                <Link to="/contact-us">Contact us</Link>
              </p>
            </Col>
            <Col xs="12">
              <p>
                <Link to="/services">Services</Link>
              </p>
            </Col>
            <Col xs="12">
              <p>
                <Link to="/gallery">Gallery</Link>
              </p>
            </Col>
          </Row>
        </Col>
        <Col xs="6" sm="3" md="4" xl="4">
          <section className={classes.clients}>Clients</section>
          <Row>
            <Col xs="12" sm="12" md="6" xl="6">
              <p>Hyatt Group</p>
            </Col>
            <Col xs="12" sm="12" md="6" xl="6">
              <p>Capitol Hotel</p>
            </Col>
            <Col xs="12" sm="12" md="6" xl="6">
              <p>Byblos Hospitality Group</p>
            </Col>
            <Col xs="12" sm="12" md="6" xl="6">
              <p>Mandarin Oriental Hotel Group</p>
            </Col>
            <Col xs="12" sm="12" md="6" xl="6">
              <p>Buddha-Bar Grosvenor House</p>
            </Col>
            <Col xs="12" sm="12" md="6" xl="6">
              <p> Hilton Hotel Habtoor City</p>
            </Col>
            <Col xs="12" sm="12" md="6" xl="6">
              <p>Little Manila Restaurant Dubai</p>
            </Col>
            <Col xs="12" sm="12" md="6" xl="6">
              <p>Habtoor Grand Resort</p>
            </Col>
            <Col xs="12" sm="12" md="6" xl="6">
              <p>Marriot Hotels</p>
            </Col>
          </Row>
        </Col>
        <Col xs="12" sm="6" md="4" xl="5" className={classes.footer_clause}>
          <Row>
            <Col xs="12" className={classes.tou}>
              By continuing past this page, you agree to{" "}
              <span>Terms of Use</span>.<br /> All trademarks are properties of
              their respective owners.
            </Col>
          </Row>
          <Row>
            <Col xs="12" className={classes.footer_sm}>
              <a
                href="https://www.facebook.com/manhattan.furniture.3"
                target="_blank"
                rel="noopener noreferrer"
                title="Facebook - Manhattan Furniture Ind Co LLC"
              >
                <FontAwesomeIcon icon={faFacebookSquare} className="fa-2x" />
              </a>
              {"  "}
              <a
                href="https://www.instagram.com/manhattanfurnitureco/"
                target="_blank"
                rel="noopener noreferrer"
                title="Instagram - Manhattan Furniture Ind Co LLC"
              >
                <FontAwesomeIcon icon={faInstagramSquare} className="fa-2x" />
              </a>
              {"  "}
              <FontAwesomeIcon icon={faTwitterSquare} className="fa-2x" />
              {"  "}
              <a
                href="https://www.linkedin.com/company/manhattan-furniture-ind-co-llc/"
                target="_blank"
                rel="noopener noreferrer"
                title="Linkedin - Manhattan Furniture Ind Co LLC"
              >
                <FontAwesomeIcon icon={faLinkedin} className="fa-2x" />
                
              </a>
              {"  "}
              {/*<FontAwesomeIcon icon={faWhatsappSquare} className="fa-2x" />{" "}*/}
            </Col>
          </Row>
          <Row>
            <Col xs="12" className={classes.footer_cr}>
              Copyright© FIRST NUMBER FURNITURE IND LLC. All rights reserved.
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
    /*
      <div>
       
      </div>
      */
  );
}

export default Footer;
