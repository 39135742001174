import classes from "./Contactus.module.css";
import { Container, Row, Col } from "reactstrap";
import { useRef, useState } from "react";
import { FaRegWindowClose, FaCheckCircle } from "react-icons/fa";

function Contactus() {
  const fullNameInputRef = useRef();
  const emailInputRef = useRef();
  const contactNoInputRef = useRef();
  const messageInputRef = useRef();
  const [isSubmitInProgress, setIsSubmitInProgress] = useState(false);

  const [error, setError] = useState(null);
  const errorDiv = error ? (
    <div className="error">
      <FaRegWindowClose size={20} color="red" />{" "}
      {error}
    </div>
  ) : (
    ""
  );

  const [sendStatus, setSendStatus] = useState(null);
  const sendStatusDiv = sendStatus ? (
    <div className="success">
      <FaCheckCircle size={20} color="green" /> {sendStatus}
    </div>
  ) : (
    ""
  );

  function clearForm() {
    fullNameInputRef.current.value = "";
    emailInputRef.current.value = "";
    contactNoInputRef.current.value = "";
    messageInputRef.current.value = "";
  }
  function submitHandler(event) {
    setIsSubmitInProgress(true);
    event.preventDefault();

    setSendStatus (null);
    setError (null);

    const enteredFullName = fullNameInputRef.current.value;
    const enteredEmail = emailInputRef.current.value;
    const enteredContactNo = contactNoInputRef.current.value;
    const enteredMessage = messageInputRef.current.value;

    const enqueryMail = {
      FullName: enteredFullName,
      ContactEmailId: enteredEmail,
      ContactNo: enteredContactNo,
      EnquiryMessage: enteredMessage,
    };

    console.log(enqueryMail);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(enqueryMail),
    };

    fetch(`${process.env.PUBLIC_URL}/api/email/enquiry`, requestOptions)
      .then(async (response) => {
        const isJson = response.headers
          .get("content-type")
          ?.includes("application/json");
        const data = isJson && (await response.json());
        console.log("data", data);
        console.log("response", response);
        // check for error response
        if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        } else {
          setSendStatus(
            "Your Enquiery has been sumbitted. Our team will contact you shortly!"
          );
          clearForm();
          console.log("inside success!");
        }
      })
      .catch((error) => {
        setError(
          "Could not submit your request due to some technical issue. Please try after some time!"
        );
        console.error("There was an error!", error);
      });
    setIsSubmitInProgress(false);
    console.log("Send Mail");
  }

  return (
    <Container>
      <Row>
        <Col xs="12" className={classes.header}>
          <header> CONTACT DETAILS</header>
        </Col>
      </Row>
      <Row className={classes.contactus}>
        <Col xs="12" sm="12" md="3" xl="3">
          <Row className={classes.card}>
            <Col col="xs">
              <h3>FIRST NUMBER FURNITURE IND LLC</h3>
              <p>Industrial Area 13</p>
              <p>Plot No 231</p>
              <p>Shed 12</p>
              <p>Street No 13,</p>
              <p>Sharjah, U.A.E.</p>
              <p>Post Office Box: 84209</p>
            </Col>
          </Row>
          <Row className={classes.card}>
            <Col col="xs">
              <h3>Email</h3>
                          <a href="mailto:info@firstnumberfurniture.com?subject=Enquiry&amp;body=Hello,">
                              info@firstnumberfurniture.com
              </a>
            </Col>
          </Row>
          <Row className={classes.card}>
            <Col col="xs">
              <h3>Mobile</h3>
              <p>065366172</p>
              <p>0581353496</p>
            </Col>
          </Row>
        </Col>

        <Col xs="12" sm="12" md="9" xl="9">
          <form className={classes.form} onSubmit={submitHandler}>
            <div className={classes.control}>
              <label htmlFor="fullName">Full Name</label>
              <input
                type="text"
                required
                id="fullName"
                ref={fullNameInputRef}
              />
            </div>
            <div className={classes.control}>
              <label htmlFor="email">Email</label>
              <input type="email" required id="email" ref={emailInputRef} />
            </div>
            <div className={classes.control}>
              <label htmlFor="contactNo">Contact Number</label>
              <input
                type="text"
                required
                id="contactNo"
                ref={contactNoInputRef}
              />
            </div>
            <div className={classes.control}>
              <label htmlFor="message">Message</label>
              <textarea
                id="message"
                required
                rows="7"
                ref={messageInputRef}
              ></textarea>
            </div>
            <div className={classes.actions}>
              {errorDiv}
              {sendStatusDiv}
              <button disabled={isSubmitInProgress}>Submit</button>
            </div>
          </form>
        </Col>
      </Row>
    </Container>
  );
}

export default Contactus;
