import { Col, Container, Row } from 'reactstrap'
import classes from './Home.module.css'
import bannerImg from '../assets/banner.jpg'

function Home(){
    return (
      <Container>
        <Row>
          <Col>
            <div
              className={classes.banner}
              style={{ backgroundImage: `url(${bannerImg})` }}
            >
              <p>BRINGING DREAMS TO LIFE</p>
            </div>
          </Col>
        </Row>
        {/*
        <Row>
          <Col>
            <Row>
              <Col>image set 1</Col>
            </Row>
            <Row>
              <Col>image set 2</Col>
            </Row>
            <Row>
              <Col>image set 3</Col>
            </Row>
          </Col>
        </Row>
        */}
      </Container>
    );
}

export default Home;