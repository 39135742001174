import { BrowserRouter, Route, Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import classes from "./Header.module.css";
import logo from '../../assets/logo.jpg';
import logo1 from "../../assets/logo1.png";

function Header() {
  return (
    <Container className={classes.header}>
      <Row>
        <Col xs="12" sm="4" md="3" xl="3">
          <Row className={classes.logo}>
            <Col>
              {" "}
              <a href="/">
                <img src={logo} alt="FIRST NUMBER FURNITURE IND LLC" />
              </a>
            </Col>
          </Row>
        </Col>
        <Col xs="12" sm="8" md="9" xl="9" className={classes.contact}>
          <Row className={classes.info}>
            <Col xs="12" sm="6" xl="9">
              0581353496
            </Col>
            <Col xs="12" sm="6" xl="3">
                          <a href="mailto:info@firstnumberfurniture.com?subject=Enquiry&amp;body=Hello,">
                              info@firstnumberfurniture.com
              </a>
            </Col>
          </Row>
          <Row className={classes.header_links}>
            <Col xs="3" sm="3">
              <Link to="/about-us">ABOUT US</Link>
            </Col>
            <Col xs="3" sm="3">
              <Link to="/contact-us">CONTACT US</Link>
            </Col>
            <Col xs="3" sm="3">
              <Link to="/services">SERVICES</Link>
            </Col>
            <Col xs="3" sm="3">
              <Link to="/gallery">GALLERY</Link>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}

export default Header;
