import {
  faFacebookF,
  faLinkedin,
  faLinkedinIn,
  faTwitterSquare,
  faInstagram,
  faMailchimp,
  faInstagramSquare,
  
  
} from "@fortawesome/free-brands-svg-icons";



import React, { Fragment } from "react";
import { Route, Routes } from "react-router-dom";
import axios from "axios";

import logo from "./logo.svg";
import "./App.css";
import "bootstrap/dist/css/bootstrap.css";

import HomePage from "./pages/HomePage";
import Layout from "./components/Layouts/Layout";
import AboutusPage from "./pages/AboutusPage";
import ContactusPage from "./pages/ContactusPage";
import ServicesPage from "./pages/ServicesPage";
import GalleryPage from "./pages/GalleryPage";
import { library } from "@fortawesome/fontawesome-svg-core";

library.add(
  faFacebookF,
  faLinkedin,
  faLinkedinIn,
  faTwitterSquare,
  faInstagram,
  faInstagramSquare,
 
);

function App() {
  return (
    <Layout>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/contact-us" element={<ContactusPage />} />
        <Route path="/about-us" element={<AboutusPage />} />
        <Route path="/services" element={<ServicesPage />} />
        <Route path="/gallery" element={<GalleryPage />} />
      </Routes>
    </Layout>
  );
}

export default App;
/*<GalleryPage />}*/