import { Container, Row, Col } from "reactstrap";
import tvunit from "../assets/services/tvunit.jpg";
import kitchen from "../assets/services/kitchenunit.jpg";
import upholstery from "../assets/services/upholstery.jpg";
import indoor from "../assets/services/indoor.jpg";

import painting from "../assets/services/painting.jpg";
import curtain from "../assets/services/curtain.jpg";
import bespokejoinery from "../assets/services/bespokejoinery.jpg";
import gypsum from "../assets/services/gypsum.jpg";
import renovations from "../assets/services/renovation.jpg";


import ServiceList from "../components/services/ServiceList";


function ServicesPage() {
  const SERVICES_DATA = [
    {
      id: 1,
      title: "Bespoke Joinery",
      image: bespokejoinery,
      description:
        "Wood is one of the best materials for use in construction. It is highly sustainable, as production and processing of said material takes less energy than most building components. It is a structurally sound component that can double as a natural insulator against harsh weather. Additionally, it has an organically warm appearance that allows it to blend into virtually any design or aesthetic arrangement. It uniquely offers variety and uniformity at the same time, with its naturally formed wood grains. Maximise the beauty and function of wood with the help of world-class joinery works – partner with one of the premiere woodwork firms in U.A.E. today,",
      description1: "JOINERY WITH A DIFFERENCE",
    },

    {
      id: 2,
      title: "Upholtery",
      image: upholstery,
      description:
        "People when choosing upholstery, your primary concern should be maintenance. Always use commercial-grade upholstery that has been treated with stain-resistant chemicals to maximize its useful life. Commercial-grade vinyl comes in many colors, patterns, and textures and is durable and easy to clean. Some of the higher grades are indistinguishable from leather and could easily — Roger Fields",
      description1: "",
    },
    {
      id: 3,
      title: "Painting",
      image: painting,
      description:
        "A fantastic painting project may completely transform the look and feel of your home. A high-quality exterior paint can protect your home’s exterior from the harsh Dubai sun and arid climate while still maintaining its scaled-down attractiveness. Meanwhile, the perfect paint colors on the inside of your home can keep it looking and feeling fresh, light, and airy. If you don’t want to paint your home yourself, Space Makeover has a team of professional painters who can assist you. Smaller works, such as single rooms and walls, can be quoted immediately.",
      description1:
        "If you need major painting, such as for multiple rooms or the exterior of your home, one of our specialists will come to you to assess the job before providing you with a detailed quote.",
    },
    {
      id: 4,
      title: "Kitchen Cabinets",
      image: kitchen,
      description:
        "The exceptionally adaptable and easy to-deal with interior accessories system is based around exquisite aluminum trays and frames in different widths and depths that are attached to the multifunction track with no visible hooks. This multifunctional track can be equipped independently and practically with various accessories so that everything truly finds its ideal place. With the versatile system and professional storage planning by your kitchen designer, you can carefully plan to suit your current space. See for yourself.",
      description1:
        "With Manhattan, the design philosophy of the interior accessories for pull outs and drawers has been applied for all tall, base, and wall units. This profoundly versatile, offers a similar aesthetic that comes from a sophisticated combination of high quality materials such as aluminum, valuable woods, and fine porcelain as well as various other functions perfected down to the last detail.",
    },
    {
      id: 5,
      title: "Renovations",
      image: renovations,
      description: "Mention something about Renovations",
      description1: "",
    },

    /*
      /*
    {
      id: 2,
      title: "Shades & Curtains",
      image: curtain,
      description: "Mention something about Shades & Curtains",
      description1: "",
    },
    
   

    {
      id: 7,
      title: "Gypsum Works",
      image: gypsum,
      description: "Mention something about Gypsum Works",
      description1: "",
    },
    
    {
      id: 8,
      title: "TV Units",
      image: tvunit,
      description: "Mention something about TV Units",
      description1: "",
    },*/
  ];

  return <ServiceList services = {SERVICES_DATA}/>;
}

export default ServicesPage;
