import { Component } from "react";
import cssClasses from "./SearchItem.module.css"; 

function SearchItem(props) {
  return (
    <div className={cssClasses.schTag} onClick={props.handleClick}>
      {props.category}{" "}
    </div>
  );
}

export default SearchItem;
