import classes from "./Aboutus.module.css";
import { Container, Row, Col } from "reactstrap";
import tvunit from "../assets/specialisation/tvunit.jpg";
import kitchen from "../assets/specialisation/kitchen.jpg";
import upholstery from "../assets/specialisation/upholstery.jpg";
import indoor from "../assets/specialisation/indoor.jpg";


function Aboutus() {
  return (
    <Container>
      <Row>
        <Col xs="12" className={classes.header}>
          <header> ABOUT US</header>
        </Col>
      </Row>
      <Row className={classes.aboutus}>
        <Col xs="12" sm="12" md="12" xl="12">
          <Row className={classes.card}>
            <Col col="xs">
              <p>
                First Number Furniture have forged an enviable reputation in Interior and Exterior
                Contracting, transforming creative concepts into the highest
                quality contracts. A powerful legacy of this experience is the
                close working relationship we like to form with every client's
                design team. Initiating grounds in design conceptulization and a
                keen eye for detail manufacturing to installations. Today First Number Furniture's
                expertise extends to a range of sectors including high end
                Residential and Commercial fit-out works all over the United
                Arab Emirates. In all cases First Number Furniture prides itself on hands-on
                project management and strict cost control. We would be happy to
                provide reference for our wor
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              {" "}
              <h3> OUR SPECIALISATION</h3>
            </Col>
          </Row>
          <Row>
            <Col xs="12" sm="12" md="6" xl="3">
              <div
                className={classes.box}
                style={{
                  backgroundImage: `url(${kitchen})`,
                }}
              >
                <p>KITCHEN UNITS </p>
              </div>
            </Col>
            <Col xs="12" sm="12" md="6" xl="3">
              <div
                className={classes.box}
                style={{ backgroundImage: `url(${tvunit})` }}
              >
                <p>TV UNITS</p>
              </div>
            </Col>
            <Col xs="12" sm="12" md="6" xl="3">
              <div
                className={classes.box}
                style={{ backgroundImage: `url(${upholstery})` }}
              >
                <p>Upholstery</p>
              </div>
            </Col>
            <Col xs="12" sm="12" md="6" xl="3">
              <div
                className={classes.box}
                style={{ backgroundImage: `url(${indoor})` }}
              >
                <p>Indoor</p>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}

export default Aboutus;
