import { Col, Container, Row } from "reactstrap";
import ServiceItem from "./ServiceItem";

import classes from "./ServiceList.module.css";
import "../../index.css";

function ServiceList(props) {
  return (
    <Container className={classes.wrapper}>
      <Row>
        <Col className={classes.header}>
          <p>
            First Number Furniture offer a full service from initial design right through to
            completion. We fabricate, manufacture, construct and install
            something you are proud of and something that meets your exact
            specifications and requirements. We take pride of conceptualization
            of products &amp; materials used for the processes to successfully
            satisfy every need of our client.
          </p>
        </Col>
      </Row>
      <Row style={{ marginTo: "5px" }}>
        <Col className="align-ct">
          <h2>OUR SERVICES</h2>
        </Col>
      </Row>
      <Row style={{ marginTo: "5px" }}>
        <Col>
          {" "}
          {props.services.map((service) => (
            <ServiceItem
              key={service.id}
              id={service.id}
              image={service.image}
              title={service.title}
              description={service.description}
              description1={service.description1}
            />
          ))}
        </Col>
      </Row>
    </Container>
  );
}

export default ServiceList;
