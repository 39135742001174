import { Col, Container, Row } from "reactstrap";
import classes from './ServiceItem.module.css'
import  '../../index.css';
import  "./ServiceItem.module.css";

function ServiceItem(props) {
  return (
    <Container>
      <Row>
        {props.id % 2 == 1 && (
          <Col xs="12" sm="12" md="5" xl="5">
            {" "}
            <div
              className="box"
              style={{ backgroundImage: `url(${props.image})` }}
            >
              <p className={classes.title}>{props.title}</p>
            </div>
          </Col>
        )}
        <Col xs={({ span: 12 }, { order: 1 })} sm="12" md="7" xl="7">
          <div className={classes.desc}>
            <span>
              <p className="vh_middle">{props.description}</p>
              {props.description1 != "" && (
                <p className="vh_middle">{props.description1}</p>
              )}
            </span>
          </div>
        </Col>
        {props.id % 2 == 0 && (
          <Col xs={({ span: 12 }, { order: 2 })} sm="12" md="5" xl="5">
            {" "}
            <div
              className="box"
              style={{ backgroundImage: `url(${props.image})` }}
            >
              <p className={classes.title}>{props.title}</p>
            </div>
          </Col>
        )}
      </Row>
    </Container>
  );
}

export default ServiceItem;
