import React, { Fragment } from "react";

import Contactus from "../components/Contactus";

function ContactusPage() {
  return (
    <Contactus/>
    
  );
}

export default ContactusPage;
