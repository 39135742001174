import { useState, useEffect } from "react";
import GalleryViewer from "../components/Gallery/GalleryViewer";
//import { GALLERY_IMAGES } from "../data/GalleryData.js";

function GalleryPage() {
  useEffect(() => {
    const tt = GALLERY_IMAGES;
    //setGalleryImages(tt);

  }, []);

  const GALLERY_IMAGES = [
    {
      id: 1,
      category: "Bespoke Joinery",
      image: "/assets/gallery/1.jpg",
    },
    {
      id: 2,
      category: "Shades & Curtains",
      image: "/assets/gallery/2.jpg",
    },
    {
      id: 3,
      category: "Upholtery",
      image: "/assets/gallery/3.jpg",
    },
    {
      id: 4,
      category: "Painting",
      image: "/assets/gallery/4.jpg",
    },
    {
      id: 5,
      category: "Kitchen Units",
      image: "/assets/gallery/5.jpg",
    },
    {
      id: 6,
      category: "Renovations",
      image: "/assets/gallery/6.jpg",
    },
    {
      id: 7,
      category: "Gypsum Works",
      image: "/assets/gallery/7.jpg",
    },
    {
      id: 8,
      category: "TV Units",
      image: "/assets/gallery/8.jpg",
    },
    {
      id: 9,
      category: "TV Units",
      image: "/assets/gallery/9.jpg",
    },
    {
      id: 10,
      category: "Renovations",
      image: "/assets/gallery/10.jpg",
    },
    {
      id: 11,
      category: "Renovations",
      image: "/assets/gallery/11.jpg",
    },
    {
      id: 12,
      category: "Renovations",
      image: "/assets/gallery/12.jpg",
    },
    {
      id: 13,
      category: "Renovations",
      image: "/assets/gallery/13.jpg",
    },
    {
      id: 14,
      category: "Renovations",
      image: "/assets/gallery/14.jpg",
    },
    {
      id: 15,
      category: "Renovations",
      image: "/assets/gallery/15.jpg",
    },
    {
      id: 16,
      category: "Renovations",
      image: "/assets/gallery/16.jpg",
    },
    {
      id: 17,
      category: "Renovations",
      image: "/assets/gallery/17.jpg",
    },
    {
      id: 18,
      category: "Renovations",
      image: "/assets/gallery/18.jpg",
    },
  ];

  return <GalleryViewer images={GALLERY_IMAGES} />;
}

export default GalleryPage;
